//table component
.commonTableComponent{
	overflow: hidden;
    @media (min-width: 850px) and (max-width: 1285px) {
        table tr td:nth-child(1){
            padding-left: 12px !important;
        }
        table thead th:nth-child(1){
            padding-left: 12px !important;
        }
      }
	  .table thead th{
		  border-bottom:0;
	  }
}

// Loader style
.loaderOuterContainer{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color:rgba(0, 0, 0, 0.80);
}
.loader {
	position: absolute;
	width: 40px;
	height: 40px;
	top: 54%;
	left: 48%;
    transform: translate(-50%, -50%);
    list-style-type: none;
    
}

.loader .center {
    //background: url('../ImageAssets/blueAvatar.png');
	width: 40px;
	height: 40px;
	background: #FFFFFF;
	border-radius: 50%;
    animation: center 3.2s ease-in-out infinite;
    position: absolute;
    left: 17%;
}

.loader .item {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 15px;
	left: 0;
	right: 0;
	margin: auto;
	background: #FFFFFF;
	border-radius: 50%;
}

.item-1 {
	animation: anim-1 3.2s ease-in-out infinite 0.2s;
	animation-fill-mode: backwards;
}

@keyframes anim-1 {
	0%, 60%, 100% {
		transform: rotate(45deg) translateX(40px) scale(1);
	}

	10%, 50% {
		transform: rotate(45deg) translateX(0) scale(1.5);
	}
}

.item-2 {
	animation: anim-2 3.2s ease-in-out infinite 0.4s;
	animation-fill-mode: backwards;
}

@keyframes anim-2 {
	0%, 60%, 100% {
		transform: rotate(90deg) translateX(40px) scale(1);
	}

	10%, 50% {
		transform: rotate(90deg) translateX(0) scale(1.5);
	}
}

.item-3 {
	animation: anim-3 3.2s ease-in-out infinite 0.6s;
	animation-fill-mode: backwards;
}

@keyframes anim-3 {
	0%, 60%, 100% {
		transform: rotate(135deg) translateX(40px) scale(1);
	}

	10%, 50% {
		transform: rotate(135deg) translateX(0) scale(1.5);
	}
}

.item-4 {
	animation: anim-4 3.2s ease-in-out infinite 0.8s;
	animation-fill-mode: backwards;
}

@keyframes anim-4 {
	0%, 60%, 100% {
		transform: rotate(180deg) translateX(40px) scale(1);
	}

	10%, 50% {
		transform: rotate(180deg) translateX(0) scale(1.5);
	}
}

.item-5 {
	animation: anim-5 3.2s ease-in-out infinite 1s;
	animation-fill-mode: backwards;
}

@keyframes anim-5 {
	0%, 60%, 100% {
		transform: rotate(225deg) translateX(40px) scale(1);
	}

	10%, 50% {
		transform: rotate(225deg) translateX(0) scale(1.5);
	}
}

.item-6 {
	animation: anim-6 3.2s ease-in-out infinite 1.2s;
	animation-fill-mode: backwards;
}

@keyframes anim-6 {
	0%, 60%, 100% {
		transform: rotate(270deg) translateX(40px) scale(1);
	}

	10%, 50% {
		transform: rotate(270deg) translateX(0) scale(1.5);
	}
}

.item-7 {
	animation: anim-7 3.2s ease-in-out infinite 1.4s;
	animation-fill-mode: backwards;
}

@keyframes anim-7 {
	0%, 60%, 100% {
		transform: rotate(315deg) translateX(40px) scale(1);
	}

	10%, 50% {
		transform: rotate(315deg) translateX(0) scale(1.5);
	}
}

.item-8 {
	animation: anim-8 3.2s ease-in-out infinite 1.6s;
	animation-fill-mode: backwards;
}

@keyframes anim-8 {
	0%, 60%, 100% {
		transform: rotate(360deg) translateX(40px) scale(1);
	}

	10%, 50% {
		transform: rotate(360deg) translateX(0) scale(1.5);
	}
}

@keyframes center {
	0%, 10%, 90%, 100% {
		transform: scale(0.7);
	}

	45%, 55% {
		transform: scale(1);
	}
}

//popup style

.modal-sm {
    max-width: 484px !important;
}
.popupOuterMostContainer{
    .popupMainHeading{
        color: #ffffff;
        font-size: 20px;
        font-weight: 700;
    }
    .popupContent{
        color: #58595B;
        font-size: 12px;
    }
    .cancelButton{
        height: 40px;
        width: 140px;
        border: 1px solid #6C63FF;
        border-radius: 4px;
        background-color: #FFFFFF;
        color: #6C63FF;
        font-size: 14px;
        font-weight: bold;
        box-shadow: none !important;
    }
    .submitButton{
        height: 40px;
        width: 140px;
        border-radius: 4px;
        background: #6C63FF;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: bold;
        box-shadow: none !important;
        border: 0px;
        &:hover {
            color: #FFFFFF !important;
        }
    }
}

.modal{
	.modal-header{
		background-color: #6C63FF;
		.popupMainHeading{
			color: #ffffff;
			font-size: 20px;
			font-weight: 700;
		}
	}
	.input_data{
		box-shadow: unset !important;
		outline:none;
	}
}

//sliderModal
.commonPopupFormBackDrop{
    background-color: rgba(46, 44, 44, 0.58);
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 150;
}
.commonPopupOuterMostContainer{
    position: fixed;
    display: flex;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 200;
    justify-content: flex-end;
    .editable{
        color: #6C63FF;
        font-size: 10px;
    }
    .leftTransparentPart{
        cursor:pointer
    }
    .contentPart{
        background-color: #FFFFFF;
        z-index: 2;
        float: right;
        display: flex;
        flex-direction: column;
        // flex:8;
        .sliderMainHeading{
            padding: 20px;
            background-color:#6C63FF;
            color: #FFFFFF;
            font-size: 20px;
            letter-spacing: 0;
            line-height: 30px;
            text-align: justify;
            font-weight: 300;
        }
        .sliderSubHeading{
            padding: 10px;
            padding-bottom: 40px;
            height: 30px;
            color: #33495F;
            font-size: 14px;
            font-weight: 600;
            line-height: 30px;
        }
        .formsContainerBox{
            padding: 15px;overflow-y:auto;flex: 1;
            .formGroup_label{
                height: 30px;
                color: #7E91A4;
                font-size: 12px;
                line-height: 30px;
            }
            .formGroup_value{
                color: #000000;
                height: 40px;
                border: 1px solid #eaedf0;
                border-radius: 4px;
                background-color: #FFFFFF;
            }
            .headingRow{
                display: flex;font-weight: bold;font-size: 14px;padding: 10px;
                .headingRow_column{
                    flex:1;
                    height: 30px;
                    color: #7E91A4;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 30px;
                }
            }
            .dataRowContainer{
                background-color: #FFFFFF;border-radius: 4px;
                .dataRowContainer_row{
                    display:flex;font-size: 14px;padding: 10px;border: 1px solid #eaedf0;border-radius: 3px;margin-bottom: 3px;
                    .rowColumn{
                        flex:1;
                        height: 50px;
                        color: #7E91A4;
                        font-size: 12px;
                        line-height: 50px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
      
        .buttonGroup{
                padding:10px;
                text-align: right;
                height: 60px;
                border: 1px solid #eaedf0;
                background-color: #FFFFFF;
            .whiteButton{
                    height: 40px;
                    width: 140px;
                    border: 1px solid #6C63FF;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    box-shadow: 0 0 30px 0 rgba(0,0,0,0.05);
                    color: #6C63FF;
            }

              
            .redButton{
                height: 40px;
                width: 140px;
                border-radius: 4px;
                background: #6C63FF;
                color: #FFFFFF;
                box-shadow: 0 0 30px 0 rgba(0,0,0,0.05);
            }
        }
    }
}


