.councInbox {
    background: #FAFBFD;
}
.councInboxLeftSec {
    background: #F5F7FB;
}
.councInboxStudSel {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DEDCFB;
    border-radius: 5px;
    opacity: 1;

}
.councInboxStudSel .counStudNormalText {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: bold ;
    color: #272727;
}
.counInboxBoldText {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold ;
    color: #272727;
}
.counOnlineCircle {
    background: #15AC26 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.chatArea {
    background: #F5F7FB 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 10px;
    padding: 4% 0;
    opacity: 1;
    height: 72vh;
    overflow: scroll;
}

.inboxTextArea {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #AFAFAF;
    border-radius: 30px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    padding: 1%;
}
.inboxTextArea input {
    border: none;
    outline: none;
    width: 100%;
    letter-spacing: 0px;
    color: #AFAFAF;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
}

.councInboxDoc {
    background: #F5F7FB 0% 0% no-repeat padding-box;
    width: 40px;
    height: 35px;
    border-radius: 22px;
    color: #6A5DFB;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.councInboxSend {
    background: #6A5DFB 0% 0% no-repeat padding-box;
    border-radius: 22px;
    opacity: 1;
    width: 100px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}
.messageWrapOutSm {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    // width: 100px;
    min-width: 20%;
    max-width: 40%;
    height: 40px;
}
// .messageWrapOutLg {
//     background: #FFFFFF 0% 0% no-repeat padding-box;
//     border-radius: 10px;
//     opacity: 1;
//     width: 40%;
//     // width: 200px;
//     height: 40px;
// }
.messageWrapInSm {
    background: #DEDCFE 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    // width: 100px;
    min-width: 20%;
    max-width: 40%;
    height: 40px;

}
.messageWrapInLg {
    background: #DEDCFE 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    // width: 200px;
    width: 40%;
    height: 40px;
}
.userChatList {
    height: 80vh;
    overflow: scroll;
}
