.upload-docs-page-container{
    background-color: #f5f7fb;

    .searchbox-style{
        background-color:white;
        border-radius:20px;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.05), 1px 1px 4px 1px rgba(0, 0, 0, 0.05);
    }

    .card-design{
        background-color:white;
        border-radius:15px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .divider-bar{ 
        background-color: #FAB566;
        height: 2px;
    }
    .trending-head{ 
        font-weight: 800; 
        font-size: 1vh; 
    }

    ::placeholder {
        color: #D0D0D0;
        font-weight: 600;
        font-size: 12;
      }
    .sub-headings{
        font-weight: 800;
        font-size: 1.6vh;
    }

    .detail-card{
        background-color: white;
    }
.colr{
    color: gray;
}
    .zip-button{
        background-color: #EAEFF7;
        border-radius: 20px;
        color: grey;
        font-size: 1.1vh;
    }
    .tansfer-button{
        background-color: #6C63FF;
        border-radius: 20px;
        font-size: 1.3vh;
        color: white;
    }
    .tansfer-buttonNone{
        background-color:grey;
        border-radius: 20px;
        font-size: 1.3vh;
        color: white;
    }
    .expand-box{
        background-color:white;
        border-radius: 50px;
        width: 30px;
        height: 30px;
    }
    .docTopDetail{
        color: grey;
        font-size: 10px;
        font-weight: 800;
    }
    .docBottomDetail{
        color: grey;
        font-size: 8px;
    }
    .filter-dropdowns{
        background-color:white;
        border-radius: 15px;
       
    }
    
    .progress {
        width: 34px;
        height: 34px !important;
        background: none;
        box-shadow: none;
        position: relative;
    }
    
    .progress:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 4px solid #EAEFF7;
        position: absolute;
        top: 0;
        left: 0
    }
    
    .progress>span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1
    }
    
    .progress .progress-left {
        left: 0
    }
    
    .progress .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 4px;
        border-style: solid;
        position: absolute;
        top: 0
    }
    
    .progress .progress-left .progress-bar {
        left: 100%;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
        border-left: 0;
        -webkit-transform-origin: center left;
        transform-origin: center left
    }
    
    .progress .progress-right {
        right: 0
    }
    
    .progress .progress-right .progress-bar {
        left: -100%;
        border-top-left-radius: 80px;
        border-bottom-left-radius: 80px;
        border-right: 0;
        -webkit-transform-origin: center right;
        transform-origin: center right;
        animation: loading-1 1.8s linear forwards
    }
    
    .progress .progress-value {
        width: 90%;
        height: 90%;
        border-radius: 50%;
        background: #EAEFF7;
        font-size: 8px;
        color: #000;
        position: absolute;
        top: 5%;
        left: 5%
    }
    .txt{
        opacity: 0.5
    }
    .opacity{
        opacity: 0  
    }
    
    .progress.blue .progress-bar {
        border-color: #6C63FF
    }
    
    .progress.blue .progress-left .progress-bar {
        animation: loading-2 1.5s linear forwards 1.8s
    }
    
    .progress.yellow .progress-bar {
        border-color: #6C63FF
    }
    
    .progress.yellow .progress-right .progress-bar {
        animation: loading-3 1.8s linear forwards
    }
    
    .progress.yellow .progress-left .progress-bar {
        animation: none
    }
    
    @keyframes loading-1 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
        }
    
        100% {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg)
        }
    }
    
    @keyframes loading-2 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
        }
    
        100% {
            -webkit-transform: rotate(144deg);
            transform: rotate(144deg)
        }
    }
    
    @keyframes loading-3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
        }
    
        100% {
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg)
        }
    }
  
}