.splashscreen-container {
    background-color: #eaedf0;
    .loading-text{
        font-size:3vh;
        font-weight: 500;
    }
    .loader {
        border: 1vh solid #f3f3f3;
        border-radius: 50%;
        border-top: 1vh solid #6C63FF;
        border-bottom: 1vh solid #6C63FF;
        width: 10vh;
        height: 10vh;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
      }
      
      @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
}