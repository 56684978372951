.college-search-page-container{
    background-color: #f5f7fb;
    .headings-style{
        color: #D0D0D0;
        text-transform: uppercase;
        font-weight: 800;
    }
    .image-design{
        background-color: #FFFFFF;
        box-shadow: 4px 4px 8px 1px rgba(0, 0, 0, 0.4);
    }
    .image-design-uni{
        background-color: #FFFFFF;
    }
    .showAll-button{ 
        font-size: 1.5vh; 
        color: #6C63FF 
    }
    .add-button{ 
        font-size: 1.5vh; 
        color: #6C63FF; 
        font-weight: 600; 
    }
    .uni-card{
        height:200px;background-color:white;
    }

    .featherdropdownstyle{
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .commonPopupOuterMostContainer{
        position: fixed;
        display: flex;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        z-index: 200;
        justify-content: flex-end;
        .leftTransparentPart{
            // flex:1
            cursor:pointer
        }
        .contentPart{
            background-color: #FFFFFF;
            z-index: 2;
            float: right;
            display: flex;
            flex-direction: column;
            .formsContainerBox{
                padding: 15px;overflow-y:auto;flex: 1;
                .formGroup_label{
                    height: 30px;
                    color: #000;
                    font-size: 12px;
                    line-height: 30px;
                }
            }
         
        }
    }

    .searchbox-style{
        background-color:white;
        border-radius:20px;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.05), 1px 1px 4px 1px rgba(0, 0, 0, 0.05);
    }
    
    
}
.compare-section {
    height: 100%;
    position: relative;
    width: 100%;

    .left-section{
      float: left;  
      width: 50%;
    }
    .right-section{
        float: right;  
        width: 50%;
      }
      .seperator {
        height: 77%;
        width: 1px;
        background: black;
        margin: 0 auto;
        top: 0;
        bottom: 0;
        position: absolute;
        left: 50%;
      }
      .styles {
        display: inline-block;
        width: 15px;
        height: 25px;
        border: 2px solid #6C63FF;
        border-radius: 2px;
       
      }
    
}