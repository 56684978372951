#landingPageContainer{
    #login-page-left-part{
        background-color: #ffffff;
        .large-text{
            font-size:36px;
            font-weight:700;
            color:#000000;
        }
        .small-text{
            font-size:18px;
            color:#a3b4bc;
        }
        .login-btn{
            box-shadow: unset;
            outline: none;
            border-radius:25px;
            color:#ffffff;
            background-color:#6C63FF;
            font-weight: 400;
            border:2px solid #6C63FF;
        }
        .text-box-style{
            border-radius:25px;
            box-shadow: 0 2px 10px 0 #0000001a;
            outline:none;
            text-align:center;
            border:none;
        }
        ::placeholder {
            color: #a3b4bc;
        }
    }
    #login-page-right-part{
        background-color: #6C63FF;
        .sign-up-btn{
            box-shadow: unset;
            outline: none;
            border-radius:25px;
            border:2px solid #ffffff;
            color:#ffffff;
            background-color:#6C63FF;
            font-weight: 400;
        }
        .normal-text{
            font-size:30px;
            font-weight:700;
            color:#000000;
        }
    }
}