@import './animate.css';
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import './indexStyles.scss';
@import './landingStyles.scss';
@import './signUpStyles.scss';
@import './tellUsMoreStyles.scss';
@import './commonComponentStyles.scss';
@import './NewStyle/landingScreen.scss';
@import './NewStyle/signupScreen.scss';
@import './NewStyle/students/dashboard.scss';
@import './NewStyle/students/college.scss';
@import './NewStyle/students/document.scss';
@import './NewStyle/students/calender.scss';
@import './NewStyle/students/profileBuilder.scss';
@import './NewStyle/counsellor/dashboard.scss';
@import './NewStyle/counsellor/students.scss';
@import './NewStyle/counsellor/inbox.scss';
@import './NewStyle/widgets/deleteConfirmation.scss';



/* Radio button styles */

body {
  font-family: 'Montserrat', sans-serif !important;
}

$md-radio-checked-color: #fafafa;
$md-radio-border-color: #a7a7a7;
$md-radio-size: 20px;
$md-radio-checked-size: 10px; 
$md-radio-ripple-size: 15px;

@keyframes ripple {

  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
  }

  50% { 
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.RadioButton {
    margin: 16px 0;

    input[type="radio"] {
        display: none;

        &:checked + label:before {
            border-color: $md-radio-checked-color;
            animation: ripple 0.2s linear forwards; 
        }

        &:checked + label:after {
            transform: scale(1);
        }
    }

    label {
        display: inline-block;
        height:$md-radio-size;
        position: relative;
        padding: 0 ($md-radio-size + 10px);
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
        color:#c5c5c5;
        font-weight: 500;

        &:before, &:after {
            position: absolute;            
            content: '';  
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }

        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            border: 2px solid $md-radio-border-color;
        }

        &:after {
            top: $md-radio-size / 2 - $md-radio-checked-size / 2;
            left: $md-radio-size / 2 - $md-radio-checked-size / 2;
            width:$md-radio-checked-size;
            height:$md-radio-checked-size;
            transform: scale(0);
            background:$md-radio-checked-color;
        }
    }
}

.errorDesc{
  // position: absolute;
  // bottom: -15px;
  // left: 0px;
  font-size: 10px;
  color: red;
  // z-index:2;
}
// .errorDesc{
//   position: absolute;
//   top: 10px;
//   right: -80px;
//   font-size: 10px;
//   color: red;
//   z-index:2;
// }

.form-group{
  position:relative;
}

::-webkit-scrollbar {
  width: 7px;
  height:7px;
}
::-webkit-scrollbar-track {
  background: #f5f7fb;
}
::-webkit-scrollbar-thumb {
  background: #6C63FF;
  border-radius:10px
}
::-webkit-scrollbar-thumb:hover {
  background: #625be9;
}