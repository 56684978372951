.profile-builder-page-container{
    background-color: #f5f7fb;
    .headings-style{
        color: #D0D0D0;
    }
    .image-design{
        box-shadow: 4px 4px 8px 1px rgba(0, 0, 0, 0.4);
        &:hover{
            // transform: scale(1.5);
            width: 400px;
            height: 500px;
        }
    }
    .upload-files{ 
        font-size: 3vh;
        font-weight:200
    }
    .fromComputer-button{ 
        font-size:10px; 
        background-color: #6C63FF; 
        border-radius: 16px;
        color:white; 
        cursor:pointer;
    }

    .largeImages{
        width:320px;
        animation-timing-function: ease;
    }

    .smallImages{
        width:180px;
        height: 125px;
        animation-timing-function: ease;
    }

    .animationClassLargeImg{
        animation-name: larimageAnim;
        animation-duration: 1s;
        position: relative;
    }

    .animationClassSmallImg{
        animation-name: smallimageAnim;
        animation-duration: 1s;
        position: relative;
    }

    .inputfieldstyle{
        margin:0;
        padding-top: 0;
        padding: 10;
        border: 0;
        width: "100%";
        font-size: medium;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    }
    .transfer-button{
        background-color: #6C63FF;
        border-radius: 20px;
        font-size: 1.3vh;
        color: white;
    }

 


    @keyframes larimageAnim {
        from {width:20px;height:20px}
        to {width:320px;height:250px}
      }

      @keyframes smallimageAnim {
        from {width:20px;height:20px}
        to {width:180px;height:125px}
      }
}