
.templateSecWrap {
    background: #FAFBFD 0% 0% no-repeat padding-box;
    opacity: 1;
}
.tempHead {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 18px;
    margin-top: 3%;
    font-weight:bold;
}
.templateName {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 14px;
    font-weight:normal;
}
.profileSecWrap {
    background: #FAFBFD 0% 0% no-repeat padding-box;
    opacity: 1;
}
.profileItemSec {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 10px;
    opacity: 1;
    width: 100%;
    border: none;
}
.inputSecWrap {
    background: #F5F7FB 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
}
.profileBasicInput {
    width: 100%;
    outline: none;
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    letter-spacing: 0px;
    color: #AFAFAF;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
}
.profileBasicInput::placeholder {
    letter-spacing: 0px;
    color: #AFAFAF;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
}
.profileBasicLabel {
    letter-spacing: 0px;
    color: #5A5A5A;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
}