.dashborad {
    background: #FAFBFD 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-right: .5% !important;
    margin-top: 20px;
}

.profileSec {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    // box-shadow: 0px 3px 6px #00000010;
    border-radius: 10px;
    opacity: 1;
    width: 100%;
    margin-left: 5%;
    padding-left: 2%;
}

.proImageSec {
    height: 150px;
    width: 100%;
    background: #F5F7FB 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addImgBtn {
    background: #FAFBFD 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    opacity: 1;
    fill: #6A5DFB;
    width: 25px;
    height: 25px;
    padding: 10%;
    border-radius: 50%;
}

.addTodoBtn {

    fill: #6A5DFB;
    font-size: 25px;
}

.proDetailSec {
    overflow-x: hidden;
    height: 70vh;
}

.dashTitle {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 18px;
    // margin-top: 3%;
    font-weight: bold;
}

.profileTitle {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 39px;
    font-weight: bold;
}

.dashLightText {
    letter-spacing: 0px;
    color: #5A5A5A;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
}

.dashNormalText {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
}

.dashNormalText2 {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 15px;
    font-weight: bold;
}

.noDataText {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashSpanText {
    letter-spacing: 0px;
    color: #FF981D;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500px;
    margin-left: 5%;
}

.dashDeadlineText {
    letter-spacing: 0px;
    color: #FF981D;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 11px;
    line-height: 18px;
}

.dashSpanMutedText {
    letter-spacing: 0px;
    color: #AFAFAF;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500px;
    margin-left: 5%;
}

.dashTitle2 {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    margin-bottom: 2px;
}

.skillBg {
    background: #F5F7FB 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 2% 3%;
    margin-right: 5px;
}

.proImageSec .progress {
    width: 220px;
}

.proImageSec .progress-bar {
    background: #FF981D;
}

.dashHead3 {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 14px;
    padding: 2px;
    font-weight: bold;
}

.statBlocks {
    background: #F5F7FB;
    border-radius: 10px;
    // height: 80%;
}

.dashTodoCollage {
    background: #F5F7FB 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    margin: 1%;
    padding: 1%;
}

.todoSetBtn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #685DF2;
    border-radius: 5px;
    align-self: center;
    letter-spacing: 0px;
    color: #6A5DFB;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500px;
    padding: 5px;
}

.deadlineSelect {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #685DF2;
    border-radius: 5px;
    align-self: center;
    letter-spacing: 0px;
    color: #6A5DFB;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500px;
    padding: 5px;
    outline: none;
}

.deadlineSelect.deadlineSelectEssay {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFFFFF url(../../../assets1/images/down-arrow.svg) no-repeat scroll calc(95%) center/8px auto;
    ;
}

.essayOptions {
    font-size:14px;
}


.updateSquareBg {
    background: #6A5DFB 0% 0% no-repeat padding-box;
    opacity: 0.2;
    width: 45px;
    height: 45px;
}

.updateCircleBg {
    background: #6A5DFB 0% 0% no-repeat padding-box;
    opacity: 0.2;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.dashStats {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 10px;
    opacity: 1;
    padding: 2%;
}

.dashStats2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 10px;
    opacity: 1;
    overflow: scroll;
    height: 50vh;
}

.updateSec {
    height: 28vh;
    overflow-x: scroll;
    padding: 0 2%;
}

.textEnd {
    text-align: end;
}

.test-new {
    font-size: 8px !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-shadow: 0px 3px 6px #0000000F !important;

}

.custom-today-day {
    color: #FF981D !important;
    // border: 1px solid #FF981D !important;
    background-color: #f5f0eb !important;
    border-radius: 1px !important;
}

.custom-today-day::after {
    visibility: hidden;
}

.proImg {
    border-radius: 50%;
}