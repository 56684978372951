.college-page-container{
    background-color: #f5f7fb;
    .add-college-btn{
        font-weight:600;
        font-size: 12px; 
        background-color: #6C63FF;
        border-radius: 4px;
        color:#ffffff; 
        cursor: pointer;
    }
    .modify-btn{
        font-weight:600;
        font-size: 12px; 
        background-color: #6C63FF;
        border-radius: 4px;
        color:#ffffff; 
        cursor: pointer;
    }
    .tab-data{
        background-color: #ffffff;
        border-radius:5px;
        min-height:300px;
        box-shadow:0 2px 10px 0 #0000001a;
    }
}