.counQueriesWrapper{
    max-height: 90vh;
    overflow: scroll;
}
.counQueriesCard {
    display: flex;
    background-color: beige;
    padding: 2%;
    margin-bottom: 2%;
    background: #F5F7FB 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
}
.counQueriesCard2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D5D6D9;
    border-radius: 5px;
    opacity: 1;
}
.counQueriesCard3 {
    display: flex;
    background: #F5F7FB 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
}
.counsQueryTextWrap{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    width: 100%;
}

.counsDashImgbg {
    width: 35px;
    height: 35px;
    // padding: 8%;
    // margin: 2%;
    border-radius: 50%;
    // background: #ffffff;
}
.counDashNormalText{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    color: #5A5A5A;
}
.counDashLightText{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 10px;
    font-weight: normal;
    color: #5A5A5A;
}
.counDashLinkText {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 11px;
    font-weight: bold;
    color: #6A5DFB;
}

.counseler-calander{
    .Calendar.-noFocusOutline.test-new.-ltr{
        width: 320px;
    }
}
.viewQueryImg {
    width: 50px;
    height: 50px;
    // padding: 15%;
    // margin: 2%;
    border-radius: 50%;
    // background: rgb(216, 211, 211);
}
.counsQueryImgbg{
    width: 35px;
    height: 35px;
    // padding: 5%;
    // margin: 2%;
    border-radius: 50%;
    // background: #6A5DFB;
    // opacity: 0.2;
}
.dashSpanMutedText2 {
    letter-spacing: 0px;
    color: #AFAFAF;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500px;
    margin-left: 5%;
}
.counsDashDeadlineWrap {
    height: 35vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.referalBtn {
    color: #6A5DFB;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500px; 
    outline: none;
    border: none;
}