.fc-daygrid-day {
    background-color: white; /* Change the date background color */
  }
  

.fc-day-today .fc-daygrid-day-number {
    color: #FF981D;
    background-color: hsl(33, 69%, 94%); /* Change the background color for the current day */
    border:none; /* Add a border to the current day */
    margin: 5px;
  }
.fc-day-future .fc-daygrid-day-number{
    margin: 5px;
}
.fc-day-past .fc-daygrid-day-number{
    margin: 5px;
}

.fc .fc-daygrid-day.fc-day-today {
    background: none !important;
}

.fc-col-header-cell-cushion {
    color: #5D5D5D;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
}
.fc-daygrid-day-number {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: #5A5A5A;
}
.fc .fc-toolbar.fc-header-toolbar{
    display: none;
}

.fc-daygrid-event-harness .fc-event-time,.fc-daygrid-event-dot,.fc-daygrid-event-harness .fc-event-title {
    display: none;
}

.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion{
    color: #000000;
    opacity: 0.7;
    font-size: 10px;
    background-color: #d3d3d3;
    border-radius: 10px;
    min-width: 40px;
    text-align: center;
}
.fc-timegrid-event.fc-v-event{
    background-color: #F5F7FB;
    border-left: 3px solid #6C63FF;
    border-top: none;
    border-right: none;
    border-bottom: none;
}
.fc-timegrid-event .fc-event-title {
    color: #6C63FF;
    font-size: 12px;
    font-weight: 600;
    margin-left: 2%;
}
.fc-event-time {
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    margin-left: 2%;
}

.fc-timegrid-slots tbody {
    background: #FFFFFF;
}
.sloteLaneStyle2 {
    // border-bottom:1px solid #e41919 !important;
    height: 3rem !important;
    padding: 1% !important;
}
.monthHead {
    display: flex;
    // padding-left: 20%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    margin-bottom: 2%;
    background: #F5F7FB;
    border-radius: 10px 10px 0px 0px;
    justify-content: end;
}
.inboxSec {
    color: #5A5A5A;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 17px;
    font-weight: normal;
    margin-left: 30%;
}
.calDropText {
    color: #5A5A5A;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 17px;
    font-weight: normal;
}
.calDropCard {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    color: #5A5A5A;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 17px;
    font-weight: normal;
}
.monthSec {
    // margin-left: 10%;
    color: #272727;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 17px;
    font-weight: bold;
    margin-right: 30%;
}

.fc-event {
    position: relative; /* Ensure the event container is positioned relatively */
  }
  
  .fc-daygrid-event {
    width: 8px;
    height: 8px;
    position: absolute !important; /* Position the event absolutely within the day cell */
    top: -40px !important;
    right: 0 !important;
    background: #FF981D !important;
    border: #FF981D !important;
    border-radius: 50% !important;

  }


.eventHead {
    background: #F5F7FB 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
}
.eventText {
    color: #272727;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 17px;
    font-weight: bold;
    padding-top: 3%;
    padding-right: 3%;
}
.eventAddBtn {
    background: #6A5DFB 0% 0% no-repeat padding-box;
    border-radius: 22px;
    opacity: 1;
    padding: 1% 6%;
    border: 1px solid #6A5DFB;
    color: #FAFBFD;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
}
.eventList {
    background: #F5F7FB;
    padding: 0% 3%;
}
.eventTime {
    color: #5A5A5A;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
}
.eventTitle {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
}
.eventDisc {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #5A5A5A;
    opacity: 1;
}
.eventDot {
    width: 8px;
    height: 8px;
    background: #FF981D;
    border-radius: 50%;
    margin-top: 1%;
    margin-right: 2%;
}
.eventWrapper {
    height: 75vh;
    overflow: scroll;
}