.home-page-container {
    background-color: #f5f7fb;

    .headings-style {
        color: #D0D0D0;
        font-weight: 900;
    }

    .card-design {
        background-color: white;
       border-radius: 15px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .link-text-style{
      color: #99999e;
      font-size:1.3vh;
  }

    .lor-head {
        font-size: 6vh;
        font-weight: 900;
        line-height: 1;
    }

    .progress-bar {
        background-color: #6C62F6;
    }

    .divider-bar {
        background-color: #FAB566;
        height: 2px;
    }

    .trending-head {
        font-weight: 800;
        // font-size: 1vh;
    }

    .alumini-content {
        font-size: 1vh;
        font-weight: 600;
        text-align: "left";
    }

    .alumini-time {
        font-size: 1vh;
        color: grey
    }

    .spacing {
        margin-bottom: 5cm;
        margin-right: 10cm;

    }

    .opacity {
        opacity: 1
    }

    .styl {

        margin-right: 50cm;

    }

    .selstyl {
        background: transparent;
        width: 220px;
        // padding: 5px;
        border: 0;
        border-radius: 0;
        color: black;
        text-indent: 0.01px;
        text-overflow: '';
    }
    .alignment{
 margin-left: 3cm;
 margin-top: 5px;
    }
    .alg{
        margin-top: 7cm;
    }
    .inputstyl{
       
            background: transparent;
            border: none;
            border-bottom: 1px solid #000000;
        
    }
    body {
        font-family: sans-serif;
        display: grid;
        height: 100vh;
        place-items: center;
      }
      
      .base-timer {
        position: relative;
        width: 130px;
        height: 130px;
      }
      
      .base-timer__svg {
        transform: scaleX(-1);
      }
      
      .base-timer__circle {
        fill: none;
        stroke: none;
      }
      
      .base-timer__path-elapsed {
        stroke-width: 7px;
        stroke: grey;
      }
      
      .base-timer__path-remaining {
        stroke-width: 7px;
        stroke-linecap: round;
        transform: rotate(90deg);
        transform-origin: center;
        transition: 1s linear all;
        fill-rule: nonzero;
        stroke: currentColor;
      }
      
      .base-timer__path-remaining.green {
        color: rgb(65, 184, 131);
      }
      
      .base-timer__path-remaining.orange {
        color: orange;
      }
      
      .base-timer__path-remaining.red {
        color: red;
      }
      
      .base-timer__label {
        position: absolute;
        width: 130px;
        height: 130px;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
      }

      .featherdropdownstyle{
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-top: 5px;
    }
    .tansfer-button{
      background-color: #6C63FF;
      border-radius: 20px;
      font-size: 1.3vh;
      color: white;
  }

}