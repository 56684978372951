.home-page-container{
    background-color: #f5f7fb;
    .headings-style{
        color: #000000;
        font-weight: 900;
        text-transform: uppercase;
    }
    .heaidng-style{
        text-transform: uppercase;
        font-weight: 600;
    }
    .card-design{
        background-color:white;
        // border-radius:15px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .lor-head{
        font-size: 6vh;
        font-weight: 900;
        line-height: 1 ;
    }
    .progress-bar{
         background-color: #6C62F6; 
        }
    .divider-bar{ 
        background-color: #FAB566;
        height: 2px;
    }
    .trending-head{ 
        font-weight: 800; 
        font-size: 1vh; 
    }
    .alumini-content{ 
        font-size: 1vh; 
        font-weight: 600; 
        text-align: "left"; 
    }
    .alumini-time{ 
        font-size: 1vh; 
        color: grey 
    }
    .dateStyleBig {
        font-size:30px;
        font-weight:800;
        line-height:1;
        color: #5C5C5C;
    }

    .dateStyleSmall {
        font-weight:800;
        color: #5C5C5C;
        line-height:1;
        font-size: 12px;
    }

    .radioButtonSelectText{
        font-size: 12px; 
        color: #BEBEBE;
    }

    .radioButtonUnSelectText{
        font-size: 12px; 
    }

    .card-design-square{
        background-color:white;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .schedule-box{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius:20px;
        min-height:80px;
        box-shadow: 0px 3px 6px #00000029;
    }
    .normal-text{
        font-size:16px;
        font-weight:700;
        color:#000000;
    }
    .small-text{
        font-size:14px;
        color:#000000;
    }
    .extra-small-text{
        font-size:12px;
        color:#000000;
    }

    .searchbox-style{
        background-color:white;
        border-radius:20px;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.05), 1px 1px 4px 1px rgba(0, 0, 0, 0.05);
    }
    .tansfer-button{
        background-color: #6C63FF;
        border-radius: 20px;
        font-size: 1.3vh;
        color: white;
    }
}