@import './HomeStyles.scss';
@import './profileBuilderStyles.scss';
@import './collegeSearchStyles.scss';
@import './textEditorStyles.scss';
@import './uploadDocsStyles.scss';
@import './appoinmentsStyles.scss';
@import './aluminiNetworkStyles.scss';
@import './adminDashboardStyles.scss';
@import './adminCollegeStyles.scss';
@import './adminEssayStyles.scss';
@import './adminUsersStyles.scss';
@import './counsellorDashboardStyles.scss';
@import './counsellorStudentsStyles.scss';


.portal-outer-container {
    background-color: #eaedf0;

    .normal-text {
        color: #1e3770;
        font-weight: 500;
        font-size: 20px;
    }
}

.navbar-outer-container {
    background-color: #ffffff;

    .navbar-item {
        // color:#000000;
        color: #5A5A5A;
        // font-size: 12px;
        font-size: 16px;
        text-decoration: none;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

    }

    .is-active {
        // background-color: #6C63FF;
        // color:#ffffff;
        background-color: #ffffff;
        color: #6A5DFB;
        border-radius: 4px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }

    #navbar-dropdown {
        background-color: transparent;
        border: 0;
        font-size: 14px;
        outline: none;
        font-weight: 500;
        box-shadow: unset;
    }
}

.logoBg {
    background: #6A5DFB;
    width: 150px;
    height: 45px;
    margin: 5% 15%;
}

// Adding New Styles

.collegefiltersheading {
    color: #000000;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #AFAFAF;
    border-radius: 5px;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.collegefilterformgroup {
    margin: 0.5rem 0 !important;
}

.collegefilterdropdown {
    border: 1px solid #AFAFAF;
    border-radius: 5px;
    background: #ffffff;
}