
.bg-1 {
    background: url('../../assets1/images/milad-fakurian-login.png') 0% 0% no-repeat padding-box;
    background-size: cover;
    height: auto;
}
.bg-title {
    margin-top: 30px;
    margin-left: 530px;
    width: 215px;
    height: 50px;
    background: #6A5DFB 0% 0% no-repeat padding-box;
}
.loginHeading {
    margin-top: 6%;
    color: #272727;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 30px;
    line-height: 49px;
    font-weight: bold;
    opacity: 1;
}
.loginForm{
    width: 45%;
    margin-top: 2%;
}
.loginForm label {
    margin-top: 5px;
    margin-bottom: 0%;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 30px;
    font-weight: 500;
    opacity: 1;
    color: #272727;
}
.loginForm button {
    background-color: #6A5DFB;
    width: 100%;
    height: 40px;
    color: white;
    border-radius: 30px;
    border: 1px solid #6A5DFB ;
    margin-top: 5%;
}
.loginForm button:disabled {
    background-color: #6A5DFB;
    width: 100%;
    height: 40px;
    color: white;
    border-radius: 30px;
    border: 1px solid #6A5DFB ;
    margin-top: 5%;
    opacity: 0.5;
}
.loginForm .input {
    width: 100%;
    margin: 0%;
    border: 1px solid rgb(202, 198, 198);
    padding: 5px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 30px;
    font-weight: 300;
    
}

.loginForm.input::placeholder{
    letter-spacing: 0px;
    color: #AFAFAF;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 30px;
    font-weight: 300;
}

.linkText1 {
    text-align: end;
    color: #6A5DFB;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 30px;
    font-weight: 500;
}

.linkText {
    text-align: end;
    color: #6A5DFB;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 25px;
    font-weight: bold;
    cursor: pointer;
}
.otherLogin {
    margin-top: 3%;
    display: flex;
    justify-content: center;
}
.otherLogin p {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 30px;
    color: #272727;
    font-weight: normal;
    text-align: center;
}
.loginMethods {
    display: flex;
    justify-content: center;
}
.bottomText {
    margin-top: 7%;
    display: flex;
    justify-content: center;
    font-size: small;
}
.bottomText p {
    letter-spacing: 0px;
    color: #5A5A5A;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 30px;
    font-weight: normal;
    margin-top: 2%;
}

.loginMethodsBg {
    width: 45px;
    height: 45px;
    background-color: #EAEAEC;
    border-radius: 50%;
    padding-left: 8px;
    padding-top: 8px;
    
}
.loginMethodFont {
    fill: #6A5DFB;
    font-size: 30px;
}
.verifyText {
    letter-spacing: 0px;
    color: #3d3d3c;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: normal;
    
}