
.document {
    background: #FAFBFD;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
}

.docHeadText {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
}
.docSearchBox {
    background: #FFFFFF;
    display: flex;
    color: #AFAFAF;
}
.docSearchBox input {
    outline: none;
    border: none;
    width: 250px;
    padding: 2% ;
}

.docRow {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    margin: 0% 1%;
}

.docCol {
    padding: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 5px;
    opacity: 1;
    max-width: 195px;


}
.docSpanText {
    letter-spacing: 0px;
    color: #5A5A5A;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 10px;
    font-weight: normal;
}
.essayHeadText1 {
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: #272727;
}
.essayHeadText2 {
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #272727;
}
.essayHeadText3 {
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #272727;
}
.essayHeadText4 {
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #272727;
}
.essayNormalText1 {
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #5A5A5A;
}
.essayNormalText2 {
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    color: #5A5A5A;
}
.selectedText {
    color: #6A5DFB;
}

.essayLightText1 {
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #AFAFAF;
}
.essayBtn1 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #685DF2;
    border-radius: 22px;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #6A5DFB;
    padding: 5px 15px;
}

.verificationButton {
    border: none !important;
    cursor: pointer;
    font-size: 14px;
    padding: 0 1rem 0 0;
}

.essayBtn2 {
    background: #6A5DFB 0% 0% no-repeat padding-box;
    border: 1px solid #685DF2;
    border-radius: 22px;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #FAFBFD;
    padding: 5px 15px;
}
.essaySec {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 10px;
    opacity: 1;
    cursor: pointer;
}
.essayBg {
    background: #FAFBFD
}
.essayRightSec {
    background: #F5F7FB
}
.addNotesSec {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 10px;
    opacity: 1;
    margin-right: 3%;
    padding: 2%;
    height: 80vh;
}
.docColName{
    width: 100%;
    height: 30px;
    overflow: hidden;
}

.essayModal {
    height: 90vh;
    background: #F5F7FB 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #0000000D;
    border-radius: 10px;
    opacity: 1;
}

.counselerModal {
    height: 100vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px 0px 0px 10px;
    opacity: 1;
}
.essayModalContent {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 5px;
    opacity: 1;
    margin-bottom: 2%;
    margin-right: 2%;
    margin-left: 2%;
    padding: 2%;
    height: 65vh;
    overflow: scroll;
}
.modalSaveBtn {
    display: flex;
    justify-content: end;
}
.essayCloseBtn {
    font-size: 28px;
    fill: #6A5DFB;
}
.alertCloseBtn {
    font-size: 28px;
    fill: #FFFFFF;
}
.queries {
    // width: 100%;
    // height: 100vh;
    background: #FAFBFD;
}
.noQueriesWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.selectQuery {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #AFAFAF;
    color: #272727;
    border-radius: 5px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    opacity: 1;
    width: 250px;
    padding: 2%;
}
.queryModalContent {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 5px;
    opacity: 1;
    margin-bottom: 2%;
    margin-right: 2%;
    margin-left: 2%;
    padding: 2%;
    height: 55vh;
}
.querylabelWrapper {
    display: flex;
    flex-direction: column;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #272727;
    margin-left: 1%;
}
.queryLightText {
    letter-spacing: 0px;
    color: #8D8D8D;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 10px;
    font-weight: normal;
}

.queryLightText2 {
    letter-spacing: 0px;
    color: #8D8D8D;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
}
.quill-editor.active .ql-toolbar.ql-snow {
    display: block;
  }

.quill-editor .ql-toolbar.ql-snow {
    display: none;
   
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: none !important;
    // height: 40px;
    color: black;
    background: white;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    ::-webkit-scrollbar {
        width: 0.5em; /* Set a width for the scrollbar */
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent; /* Set a transparent background for the track */
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: transparent; /* Set a transparent background for the handle */
      }
}

.docTextEditor1 .ql-editor {
    background: #F4F4F4 0% 0% no-repeat padding-box;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    font-weight: normal;
    font-style: normal !important;

}

.queryTextInbox {
    width: 100%;
    height: 100%;
    outline: none;
    resize: none;
    border: none;
}

.docModal {
    width: 100%;
    text-align: center;
}
.docModalContentList {
    height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.docModalContentWrap {
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 10px;
}
.outlineRedBtn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DA1E28;
    border-radius: 22px;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #DA1E28;
    padding: 5px 15px;
}
.noFilesReqWrap {
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: ce;
}

.handleNextBtn {
    background: #EAEAEC;
    border: none;
    border-radius: 20%;
}
.essayListWrap {
    height: 50vh;
    overflow: scroll;
}
.feedListWrap {
    height: 60vh;
    overflow: scroll;
    
}
.CounEssaySecWrap {
    height: 57vh;
    overflow: scroll;

}
.timelinetabledata {
    width: 15% !important;
}
.approveBtn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #6a994e;
}

.approveBtn2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #6a994e;
    outline: none;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #6a994e;

}
.notApproveBtn2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #e63946;
    outline: none;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #e63946;
}

.feedbackWrap {
    display: flex;
    justify-content: space-between;
    background: #F5F7FB 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 5px;
    opacity: 1;
}

.acceptedStatus {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #6a994e;
  }
  
.rejectedStatus {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #e63946;
}

.pendingStatus {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #ff9505;
}