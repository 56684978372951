.alumini-network-page-container{
    background-color: #f5f7fb;
    display: flex;
    height: 100%;
    
    img{
        width: 190px;
        height: 220px;
    }

    .centerimghoverdiv{
        width: 350px; 
        height: 450px; 
        background-color: #00000030;
        z-index:2; 
        position: absolute;
    }

    .aluminidetailimghoverdiv{
        height: 220px;
        width: 215px;
        background-color: #00000030;
        z-index:2; 
        position: absolute;
    }

    .aluminidetailimghoverdiv2top{
        height: 220px;
        width: 220px;
        background-color: #00000030;
        z-index:2; 
        position: absolute;
    }

    .aluminidetailimghoverdiv2botoom{
        height: 210px;
        width: 220px;
        background-color: #00000030;
        z-index:2; 
        position: absolute;
    }

    .menuLink{
        font-weight:900;
        font-size: 12px;
    }

    .menuSubLink{
        font-weight: 200;
        font-size: 12px;
    }

}