.dashboard-page-container{
    background-color: #f5f7fb;
    .display-box{
        min-height:100px;
        box-shadow:0 2px 10px 0 #0000001a;
        border-radius:10px;
        width: 80%;
        background-color: #ffffff;
        .small-text{
            color:#231F20;
            font-weight: 500;
        }
        .big-text{
            color:#231F20;
            font-weight: 500;
            font-size: 26px;
        }
        .number-box{
            border-left:2px solid #5d56cf;
            font-size:48px;
            font-weight:500;
            color:#6C63FF;
        }
    }
}