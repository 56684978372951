.counStudLeft {
    background: #F5F7FB 0% 0% no-repeat padding-box;
    opacity: 1;
    padding-bottom: 2%;
}
.counStudHeadText {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #272727;
}
.counStudHeadText2{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-weight: bold;
    color: #272727; 
}
.counStudBoldText{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #272727;
}
.counStudBoldText2{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #272727;
}
.counStudBoldText3{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #6A5DFB;
}
.counStudBoldText4{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: #272727;
}
.counStudLightText{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #5A5A5A;
}
.counStudLightText2{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #FF981D;
}
.counStudLightText3{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #5A5A5A;
}
.counStudBgSquare {
    background: #F5F7FB;
    width: 30px;
    height: 30px;
}
.counStudBgCircle {
    // background: #6A5DFB 0% 0% no-repeat padding-box;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    // opacity: 0.2;
}
.counStudBgCircle2{
    // background: #6A5DFB 0% 0% no-repeat padding-box;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    // opacity: 0.2;
}
.counStudBgCircle3{
    background: #EAEAEC 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.counStudNameCard {
    display: flex;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500px;
    color: #272727;
    opacity: 1;
    margin: 2%;
}
.counStudNameCardactive{
    border: 1px solid #685DF2;
    border-radius: 5px;
    color: #6A5DFB;
    font-weight: bold;
}

.counStudStatCard{
    display: flex;
    justify-content: start;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    border: 1px solid #EAEAEC;
    opacity: 1;
}

.counColLeftTab {
    display: flex;
    // justify-content: space-between;
    letter-spacing: 0px;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    // background: #AFAFAF;
    margin: 5% 0%;
}
.counStudNormalText {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #272727;
}
.counStudNameCardactive .counStudNormalText {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: #6A5DFB;
}

.counStudNormalText2 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: normal;
    color: #5A5A5A;
}
.counStudNormalText3 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #272727;
}

.counStudRightHeadWrap{
    display: flex;
    justify-content: start;
    padding: 4% 2%;
}
.counStudRightUniversityWrap {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 10px;
    opacity: 1;
}
.counStudListWrap {
    height: 45vh;
    overflow: scroll;
}
.essayRejectBtn1 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DA1E28;
    border-radius: 22px;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #DA1E28;
    padding: 5px 15px;
}

.essayRejectBtn2 {
    background: #DA1E28 0% 0% no-repeat padding-box;
    border: 1px solid #DA1E28;
    border-radius: 22px;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
    padding: 5px 15px;
}
.counsAddNotesSec {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 5px;
    margin-top: 2%;
    opacity: 1;
    height: 70vh;
    overflow: scroll;
}
.colRecomModal {
    height: 92vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #0000000D;
    border-radius: 10px;
    opacity: 1;
}
.CouncEssayModal {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #0000000D;
    border-radius: 10px;
    opacity: 1;
}
.CounDocModal {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #0000000D;
    opacity: 1;
}
.CounEssaySec {
    background: #F5F7FB 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 10px;
    opacity: 1;
}
.colSearchBox2 {
    background: #F4F4F4 0% 0% no-repeat padding-box;
    color: #AFAFAF;
    padding:1% 2%;
    // margin-top: 15%;
    display: flex;
    justify-content: space-between;
   
}
.colSearchBox2 input {
    background: #F4F4F4 0% 0% no-repeat padding-box;
    border: none;
    outline: none;
    width: 100%;
    letter-spacing: 0px;
    color: #AFAFAF;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
}

.colSearchBox2 input::placeholder {
    letter-spacing: 0px;
    color: #AFAFAF;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
}

.top-right-modal .modal-dialog {
    margin-top: 1px !important;
    margin-right: 1px !important;
    margin-bottom: 1px !important;
  }

.counsStudentBottonWrap {
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden !important;
}