.text-editor-page-container{
    background-color: #f5f7fb;
    .searchbox-style{
        background-color:white;
        border-radius:20px;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.05), 1px 1px 4px 1px rgba(0, 0, 0, 0.05);
    }
    .heading-style{
        text-transform: uppercase;
        font-weight: 600;
    }
    .link-text-style{
        color: #99999e;
        font-size:1.3vh;
    }
    .card-design{
        background-color:white;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .divider-bar{ 
        background-color: #FAB566;
        height: 2px;
    }
    .trending-head{ 
        font-weight: 800; 
        font-size: 1vh; 
    }
    ::placeholder {
        color: #D0D0D0;
        font-weight: 600;
        font-size: 12;
    }

    .featherdivstyle{
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .sidemenustyle{
        box-shadow: 4px 0 10px -2px rgba(0, 0, 0, 0.19);
    }

    .dateStyleBig {
        font-size:30px;
        font-weight:800;
        line-height:1;
        color: #5C5C5C;
    }

    .dateStyleSmall {
        font-weight:800;
        color: #5C5C5C;
        line-height:1;
        font-size: 12px;
    }

    .radioButtonSelectText{
        font-size: 12px; 
        color: #BEBEBE;
    }

    .radioButtonUnSelectText{
        font-size: 12px; 
    }

    .link-button-style{
        color: blue;
        font-size: 14px;
    }

    .student-name{
        font-weight: 800;
        font-size: 14px;
    }

    .student-dept{
        font-weight: 400;
        color: grey;
        font-size: 12px;
    }
    // table {
    //     border-collapse: collapse;
    //     width: 100%;
    //     border:5px;
    //      border-spacing: 50px 0;
    // }
    
    // tr {
    //     border-bottom: 1px solid #ccc;
    // }
    // td,img { 
    //     padding:0px; 
    //     border-width:5px; 
    //     margin:0px;
    //     }
    //     img{
    //         display: block;
    //     }
}