.user-page-container{
    background-color: #f5f7fb;
    .user-page-top-div-container{
        border-bottom:1px solid #d6d6d6;
        .tab-header{
            color: #6C63FF;
            font-size: 16px;
            font-weight: 500; 
            opacity:0.7;
            min-width: 125px;
            text-align: center;
            cursor:pointer;
        }
        .active-tab-header {
            color: #6C63FF;
            font-size: 16px;
            font-weight: bold;
            opacity:1;
            border-bottom: 4px solid #6C63FF;
            border-bottom-right-radius: 1px;
            border-bottom-left-radius: 1px;
        }
    }
    .tab-data{
        background-color: #ffffff;
        border-radius:5px;
        min-height:300px;
        box-shadow:0 2px 10px 0 #0000001a;
    }
    .add-user-btn{
        font-weight:600;
        font-size: 12px; 
        background-color: #6C63FF;
        border-radius: 4px;
        color:#ffffff; 
        cursor: pointer;
    }
}