.alertHeadText {
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: normal;
    color: #FFFFFF;
}

.alertModalHead {
    display: flex;
    justify-content: space-between;
    background: #DA1E28;
}