.colSearchBox {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: #AFAFAF;
    padding:1% 2%;
    // margin-top: 15%;
    display: flex;
    justify-content: space-between;
   
}
.colSearchBox input {
    border: none;
    outline: none;
    width: 100%;
    letter-spacing: 0px;
    color: #AFAFAF;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
}

.colSearchBox input::placeholder {
    letter-spacing: 0px;
    color: #AFAFAF;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
}

.colLeftTab {
    display: flex;
    justify-content: space-around;
    letter-spacing: 0px;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    // background: #AFAFAF;
    margin: 5% 0%;

}

.colLeftSelectedBtn {
    background: #FFFFFF;
    border: 1px solid #685DF2;
    border-radius: 8px;
    color: #6A5DFB;
    margin: 2px 5px;
    padding: 2%;
}

.colLeftSelectedBtnSpan {
    background: #685DF2;
    border-radius: 8px;
    color: #FAFBFD;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    margin: 1px 3px;
    padding: 1px 6px;
}

.colLeftBtn {
    color: #272727;;
    margin: 2px 5px;
    padding: 2%;
    background: #FFFFFF;
    border-radius: 8px;
}

.colLeftBtnSpan {
    background: #5A5A5A;
    border-radius: 8px;
    color: #FAFBFD;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    margin: 1px 3px;
    padding: 1px 6px;
}

.colSelectList {
    margin-top: 1%;
    margin-bottom: 5%;
    height: 62vh;
    overflow: scroll;
}
.colSelectList.showCompareColSelect {
    height: 56vh;
}

.colNormalSmallText {
    color: #5A5A5A;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
}
.colNormalText {
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
}

.colNormalText2 {
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    overflow: hidden;
}

.colLightText {
    color: #5A5A5A;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
}
.colLightText2 {
    color: #5A5A5A;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
}
.colBox {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    // border: 1px solid #685DF2;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #0000000F;
    margin: 2% 1%;
    padding: .5%;
}
.colCheckbox {
    /* Hide the default checkbox */
    display: none;
  }

.checkbox-label {
    /* Style the label to resemble a checkbox */
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #6A5DFB;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .checkbox-label::after {
    /* Display a checkmark when the checkbox is checked */
    content: "";
    display: none;
    position: absolute;
    left: 35%;
    top:18%;
    width: 4px;
    height: 8px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
.colCheckbox:checked + .checkbox-label {
    /* Apply custom color to the checkbox when checked */
    background-color: #6A5DFB;
  }
  
  .colCheckbox:checked + .checkbox-label::after {
    /* Display the checkmark when the checkbox is checked */
    display: block;
  }

.colBoxSelected {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #685DF2;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #f133330f;
    margin: 2% 1%;
    padding: .5%;
}
.colBoxSelected h3 {
    color: #685DF2;
}

.colCoverImage {
    height: 150px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 8px;
}

.colHeadText {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 25px;
    line-height: 30px;
    font-weight: bold;
}

.colSelectBtn {
    background: #6A5DFB 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: 1px solid #6A5DFB;
    opacity: 1;
    letter-spacing: 0px;
    color: #FAFBFD;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    padding: 5px 8px;
}
.colSelectedBtn {
    background: #FAFBFD 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: 1px solid #AFAFAF;
    opacity: 1;
    letter-spacing: 0px;
    color: #5A5A5A;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    padding: 5px 8px;
}

.statHead {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
}

.statText {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
}

.statBox2{
    background: #FFFFFF;
    border: 1px solid #EAEAEC;
    border-radius: 10px;
    opacity: 1;
    padding-top: 5px;
}

.statBoxWrap {
    display: flex;
    // background-color: #4c77cc;
    position: relative;
    left: -5px;
}

.statBoxWrap1 {
    width: 160px;
    height: 62px;
}

.statBoxWrap2 {
    width: 180px;
    height: 62px;
    margin-left: 2.5%;
}

.statFont {
    fill: #FF981D;
    font-size: 28px;
    margin-top: 12px;
}

.statFont2 {
    fill: #FF981D;
    font-size: 28px;
    margin-top: 12px;
    margin-left: 12px;
}

.colLogo {
    margin-left: 55%;
    // margin-top: 25%;
    width: 50px;
    height: 50px;
}

.colTab {
    border-bottom: 1px solid rgb(226, 224, 224);
    margin-top: 2%;
}

.colTabBtnSel {
    border-bottom: 2px solid #6A5DFB !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    letter-spacing: 0px;
    color: #6A5DFB;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    background:none;
    margin: 0 2%;
}

.colTabBtn {
    border: none;
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    background:none;
    margin: 0 2%;
}

.docWrapper {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #0000000F !important;
    border-radius: 5px;
    opacity: 1;
    padding: 5% 2%;
    margin-bottom: 50px;
}
.docFontWrap {
    background: #F4F4F4 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 4px;
    opacity: 0.2;
    width: 100%;
    // width: 200px;
    height: 120px;
}

.docImgWrap {
    background: #F4F4F4 0% 0% no-repeat padding-box;
    border-radius: 4px;
    // width: 272px;
    height: 120px;
}

.docImg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.docFont {
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}

.docNameWrap {
    outline: none;
    border: none;
    background: #F4F4F4 0% 0% no-repeat padding-box;
    opacity: 1;
    margin: 5% 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #AFAFAF;
    padding: 2%;
}
.docNameWrap::placeholder {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #AFAFAF;
    padding-left: 5px;
}

.docNameWrap2 {
    outline: none;
    border: none;
    background: #F4F4F4 0% 0% no-repeat padding-box;
    opacity: 1;
    margin: 5% 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #272727;
    padding: 2%;
}

.docRemoveBtn {
    margin-left: 35%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DA1E28;
    border-radius: 22px;
    opacity: 1;
    color: #DA1E28;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 1% 5%;
}

.docEditBtn {
    margin-left: 20%;
    margin-top: 8px;
    margin-bottom: 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #685DF2;
    border-radius: 22px;
    opacity: 1;
    color: #6A5DFB;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 1% 12%;
}

.docAddBtn {
    margin-left: 30%;
    background: #6A5DFB 0% 0% no-repeat padding-box;
    border-radius: 22px;
    border: 1px solid #685DF2;
    opacity: 1;
    color: #FAFBFD;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 1% 12%;
}

.essayTable {
    box-shadow: 0px 5px 10px #00000010;
    border-radius: 10px;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 0% 2%;
    margin: 2% 0%;
}
.essayTableHeadWrap{
    width: 100%;
    height: 8.5vh;
    overflow: hidden;
}

.essayThead {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: normal;
    color: #5A5A5A;
}

.essayStatusText {
    color: #FF981D;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
}
.essayNumber {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: #272727;
    letter-spacing: 0px;
}
.timelineThead{
    background: #F5F7FB 0% 0% no-repeat padding-box;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: #272727;
    letter-spacing: 0px;
}
.timelineTbody {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: normal;
    color: #272727;
    opacity: 1;
    padding-bottom: 115px;
}

.aluminiCard {
    // background-color: lightblue;
    // color: white;
    box-shadow: 0px 3px 6px #0000000F;
    border-radius: 5px;
    border: none !important;
}

.aluminiProImg {
    padding: 5px;
}

.aluminiNum {
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
}

.galleryImg {
    object-fit: cover;
}
.compareNameWrap{
    height: 10vh;
    overflow: hidden;
}
.compareChangeBtn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #685DF2;
    border-radius: 5px;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #6A5DFB;
    padding: 2% 4%;
}

.compareText1 {
    letter-spacing: 0px;
    color: #5A5A5A;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
}
.compareText2 {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 32px;
    font-weight: bold;
}
.compareWrap {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    height: 90vh;
    padding-top: 20px;
}
.compareModal{
    background: #FAFBFD 0% 0% no-repeat padding-box;
}
.compareModalCloseBtn {
    fill: #685DF2;
    font-size: 30px;
    margin: 35% ;
}
.compareBtn {
    width: 96%;
    margin-left: 1%;
    padding: 1% 0%;
    background: #6A5DFB 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #FAFBFD;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #6A5DFB;
}
.studCollBottonWrap {
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden !important;
}
.studCollBottonWrap2 {
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden !important;
}