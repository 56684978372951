.appoinments-page-container{
    background-color: #f5f7fb;
    .right-side-bar{
        .schedule-box{
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius:20px;
            min-height:80px;
            box-shadow: 0px 3px 6px #00000029;
        }
        .normal-text{
            font-size:16px;
            font-weight:700;
            color:#000000;
        }
        .small-text{
            font-size:14px;
            color:#000000;
        }
        .extra-small-text{
            font-size:12px;
            color:#000000;
        }
        .custom-calendar {
            box-shadow: unset;
            background-color: #f5f7fb;
        }

        .Calendar{
            font-weight:800;
            .Calendar__weekDays{
                color:#b3b3b3;
                justify-content: flex-start;
                padding-right:0;
                .Calendar__weekDay{
                    text-decoration: none;
                }
                .Calendar__weekDay[title="Sunday"]{
                    display:none;
                }
            }
            .Calendar__day[aria-label^="Sunday"]{
                display:none;
            }
            .Calendar__header  {
                margin-right: 7%;
                position:relative;
                .Calendar__monthArrowWrapper.-left{
                    position:absolute;
                    right:10%;
                }
                .Calendar__monthArrowWrapper.-right{
                    position:absolute;
                    right:20%;
                }
                .Calendar__monthYearContainer{
                    position:absolute;
                    right:57%;
                }
            }
            .Calendar__section.-shown{
                padding-right:0;
            }
        }
        
        .custom-today-day {
        color: #e67e22 !important;
        border: 1px solid #e67e22 !important;
        }
        
        .custom-today-day::after {
        visibility: hidden; /* hide small border under the text */
        }
    }
    .head-text{
        font-size:18px;
        font-weight:700;
        color:#000000;
    }
    .fc-toolbar-title{
        font-size:14px;
        font-weight:700;
        color: #00000088;
    }
    .fc-toolbar-chunk{
        .fc-today-button {
            display:none;
        }
        .fc-button-group{
            display:none;
            .fc-prev-button, .fc-next-button{
                background-color: #d3d3d3;
                border-radius: 50%;
                color:#000000;
                opacity:0.7;
                border:0;
                width:30px;
                height:30px;
                font-size: 10px;
                font-weight: 600;
                box-shadow: unset;
                outline: none;
            }
            .fc-prev-button{
                margin-right:5px;
            }
        }
    }
    .fc-scrollgrid-sync-inner{
        .fc-col-header-cell-cushion {
            color: #6C63FF;
            font-weight:600;
        }
    }
    .fc-v-event{
        background-color:#ffffffbb;
        border-left: 3px solid #6C63FF;
        border-top: none;
        border-right: none;
        border-bottom: none;
    }
    .fc-event-main {
        display:flex;
        align-items: center;
    }
    .event-name{
        color: #6C63FF;
        font-size:12px;
        font-weight:600;
    }
    .event-time{
        color: #000000;
        font-size:12px;
        font-weight:600;
    }
    .fc-timegrid-slot  { 
        height: 40px;
       // border-bottom:1px solid #d3d3d3;
    }
    table,th,td{
        border:0 !important;
        overflow: hidden;
    }
    .fc-timegrid-slot-label-cushion , .fc-scrollgrid-shrink-cushion{
    color:#000000;
    opacity:0.7;
    font-size:10px;
    background-color:#d3d3d3;
    border-radius:10px;
    min-width: 40px;
    text-align: center;
    }
    .fc .fc-timegrid-col.fc-day-today{
        background-color: transparent;
    }
    .sloteLaneStyle{
        border-bottom:1px solid #d3d3d3 !important;
    }
    .fc-daygrid-day{
        border: 1px solid #d3d3d3 !important;
    }


    .card-design{
        background-color:white;
        border-radius:15px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

   
}


.guest-suggestion:hover { 
    background-color: #d3d3d3;
    cursor: pointer;
}