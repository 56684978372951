.signupPage {
    top: 0px;
    left: 0px;
    width: 1920px;
    height: 1080px;
    background: #FAFBFD 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
}
.leftBg {
    background: url('../../assets1/images/milad-fakurian-login.png') 0% 0% no-repeat padding-box;
    background-size: cover;
    height: auto;
}
.bg-title1 {
    width: 215px;
    height: 50px;
    background: #6A5DFB 0% 0% no-repeat padding-box;
    margin-top: 10%;
}
.signupHead {
    width: 100%;
}

.signupText {
    text-align: end;
    margin-right: 17%;
    margin-top: 6%;
    letter-spacing: 0px;
    color: #272727;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 30px;
    line-height: 49px;
    font-weight: bold;
}
.signupSubhead2{
    display: flex;
    justify-content: end; 
    margin-right: 12%;
    align-items: center;
}

.midLine {
    width: 90px;
    height: 1px;
    background: #CDCDCD 0% 0% no-repeat padding-box;
    // margin-top: 3%;
    margin-left: 5px;
    margin-right: 5px;
}

.currentSec {
    width: 20px;
    height: 20px;
    background: #FF981D 0% 0% no-repeat padding-box;
    font: normal normal normal 16px/22px Helvetica Neue;
    font-size: smaller;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border-radius: 50%;
    padding-left: 6px;
}
.nextSec {
    width: 20px;
    height: 20px;
    font-size: smaller;
    border-radius: 50%;
    border: 1px solid #FF981D;
    background: #FF981D00 0% 0% no-repeat padding-box;
    color: #FF981D;
    padding-left: 5px;
}

.formSection {
    display: flex;
    justify-content: center;
}
.signupForms {
    width: 45%;
}

.signupForms label{
    margin-top: 15px;
    margin-bottom: 0%;
    letter-spacing: 0px;
    color: #272727;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 30px;
    font-weight: normal;
}
.signupForms .input {
    width: 100%;
    margin: 0%;
    border: 1px solid rgb(202, 198, 198);
    padding: 10px;
    letter-spacing: 0px;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 25px;
    font-weight:300;
}
.signupForms .input::placeholder {
    color: #AFAFAF;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 30px;
    font-weight:300;
}
.signupNextBtn {
    background: #6A5DFB 0% 0% no-repeat padding-box;
    border-radius: 30px;
    border: 1px solid #EAEAEC;
    text-align: center;
    width: 172px;
    height: 40px;
    // margin-left: 52%;
    margin-top: 4%;
}
.signupNextBtndis {
    background: #EAEAEC 0% 0% no-repeat padding-box;
    border-radius: 30px;
    border: 1px solid #EAEAEC;
    text-align: center;
    width: 172px;
    height: 40px;
    // margin-left: 52%;
    margin-top: 4%;
}
.signupBtnTextdis {
    letter-spacing: 0px;
    color: #6B6B6B;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    padding-top: 5px;
    font-weight: normal;
    cursor: pointer;
}

.signupBtnText {
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    padding-top: 5px;
    font-weight: normal;
    cursor: pointer;
}

.signupHorizontal {
    height: 2px;
    width: 100%;
    background: #6A5DFB 0% 0% no-repeat padding-box;
    opacity: 0.3;
    margin-top: 2%;
}
.otherSignup {
    margin-top: 2%;
    display: flex;
    justify-content: center;
}
.otherSignup p {
    text-align: center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 25px;
    font-weight:normal;
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
}
.signupBottomText {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    justify-content: center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 25px;
    font-weight:normal;
    letter-spacing: 0px;
    color: #5A5A5A;
    opacity: 1;
}

// tell us more

.inputSec label {
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height:30px;
    font-weight:500;
    margin-bottom: 0px;
    // margin-top: 15px;
}

.inputSec .input {
    letter-spacing: 0px;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 25px;
    font-weight:300;
    border: 1px solid #AFAFAF;
}
.inputSec .input::placeholder {
    color: #AFAFAF;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 30px;
    font-weight:300;
}


.btnBack {
    border: 1px solid #6A5DFB;
    border-radius: 30px;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    letter-spacing: 0px;
    color: #6A5DFB;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height:20px;
    font-weight:500;
    width: 160px;
    height: 40px;
    margin-right: 5%;
    
}
.btnFinish {
    border: 1px solid #6A5DFB;
    border-radius: 30px;
    opacity: 1;
    background: #6A5DFB 0% 0% no-repeat padding-box;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height:20px;
    font-weight:500;
    width: 160px;
    height: 40px;
}
.btnFinishDisabled {
    border: 1px solid #6A5DFB;
    border-radius: 30px;
    opacity: 0.5;
    background: #6A5DFB 0% 0% no-repeat padding-box;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height:20px;
    font-weight:500;
    width: 160px;
    height: 40px;
}
.tellusSubhead2{
    display: flex;
    justify-content: end; 
    margin-right: 23%;
    align-items: center;
}
.input #signupProfilePic {
    display: none;
}

// input[type="file"]::-webkit-file-upload-button {
//     display: none;
// }
// .inputSec .input::-webkit-file-upload-dummy {
//     color: #FF981D;
//   }
// .inputSec .input::before {
//     content: "Choose File";
//     color: #999;
//     font-style: italic;
// }

.inputSec .input::-webkit-file-upload-button {
    display: none;
}

.inputSec .input::after {
    content: 'Browse';
    // display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #6A5DFB;
    color: #6A5DFB;
    border-radius: 22px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 4px;
    padding-top: 7px;
    // padding: 0px 8px;
    // margin-bottom: 10px;
    margin-left: 50%;
    outline: none;
    white-space: nowrap;
    // -webkit-user-select: none;
    // cursor: pointer;
    font-weight: 500;
    font-size: 12px;

    line-height: 15px;
  }

.signupSelect {
    width: 100%;
    height: 35px;
    border: 1px solid #AFAFAF;
    letter-spacing: 0px;
    color: #272727;
    opacity: 1;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 25px;
    font-weight:300;
    padding-left: 6px;
}

.signupSelect option {
    color: #6A5DFB;
}